<template>
  <div>
    <aw-tabler :table="tableJSON">
      <template #filter>
        <el-form
          class="search-form"
          :model="tableJSON.filter"
          label-suffix="："
          inline
        >
          <el-form-item label="代理商手机号" prop="username">
            <el-input placeholder="请输入" v-model="tableJSON.filter.username" clearable />
          </el-form-item>
          <el-form-item label="代理商名" prop="agent_name">
            <el-input v-model="tableJSON.filter.agent_name" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="tableJSON.filter.status" placeholder="请选择" clearable>
              <el-option label="通过" :value="1" />
              <el-option label="待处理" :value="2" />
              <el-option label="驳回" :value="3" />
            </el-select>
          </el-form-item>
          <el-form-item label="审批人" prop="audit_name">
            <el-input v-model="tableJSON.filter.audit_name" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item style="width: 440px" label="审批时间" prop="audittime">
            <el-date-picker
              style="width: 330px"
              v-model="tableJSON.filter.audittime"
              type="datetimerange"
              value-format="x"
              format="YYYY-MM-DD HH:mm:ss"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              clearable
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="店铺名" prop="store_name">
            <el-input v-model="tableJSON.filter.store_name" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="法人姓名" prop="realname">
            <el-input v-model="tableJSON.filter.realname" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="联系手机号" prop="phone">
            <el-input v-model="tableJSON.filter.phone" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="地区" prop="user_area">
            <el-cascader
              v-model="tableJSON.filter.user_area"
              :options="areaCode"
              :props="{
                  emitPath: false,
                  value: 'value',
                  label: 'label'
                }"
              clearable
            />
          </el-form-item>
          <el-form-item label="营业执照号" prop="business_license_txt">
            <el-input v-model="tableJSON.filter.business_license_txt" placeholder="请输入" clearable />
          </el-form-item>
        </el-form>
      </template>
      <template #drawercontent>
        <div v-loading="drawerLoading" class="audit-show">
          <el-row class="hd" justify="space-between">
            <el-col :span="4">
              <h2>详情</h2>
            </el-col>
            <el-col class="btns" :span="12">
              <el-button v-if="drawerData.status === 2" @click="openDispose">处理</el-button>
<!--              <el-button type="primary">下一条</el-button>-->
            </el-col>
          </el-row>
          <div class="bd">
            <div v-if="drawerData.status === 2" class="line pt-10">
              <p>处理状态：{{ drawerData.status === 1 ? '通过' : drawerData.status === 2 ? '待处理' : '驳回' }}</p>
            </div>
            <div v-else class="line pt-10">
              <p>处理状态：{{ drawerData.status === 1 ? '通过' : drawerData.status === 2 ? '待处理' : '驳回' }}</p>
              <template v-if="statusInfo">
                <p>处理时间：{{dayjs.unix(statusInfo.addtime).format('YYYY-MM-DD HH:mm:ss') }}</p>
                <p v-if="statusInfo.after_data && statusInfo.after_data.remark">回复内容：{{ statusInfo.after_data.remark }}</p>
                <p v-if="statusInfo.after_data && statusInfo.after_data.images">
                  留言图片：
                  <template v-for="(img, i) in statusInfo.after_data.images" :key="i">
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="img"
                      :initial-index="i"
                      :preview-src-list="statusInfo.after_data.images"
                    />
                  </template>
                </p>
              </template>
            </div>
            <div class="line pt-10">
              <h3>代理商信息</h3>
              <el-row :gutter="15">
<!--                <el-col v-if="agentInfo.push_agent_id" class="item" :span="6" :xs="24" :sm="12" :md="8" :lg="6">-->
<!--                  <span class="label">邀请人：</span>{{ agentInfo.push_agent_id }}-->
<!--                </el-col>-->
                <!--注意区分命名  没有前缀，不易分辨-->
                <el-col class="item" :span="6" :xs="24" :sm="12" :md="8" :lg="6">
                  <span class="label">代理商名称：</span>{{drawerData.name}}
                </el-col>
                <el-col class="item" :span="6" :xs="24" :sm="12" :md="8" :lg="6">
                  <span class="label">代理商手机号：</span>{{drawerData.username}}
                </el-col>
                <el-col class="item" :span="6" :xs="24" :sm="12" :md="8" :lg="6">
                  <span class="label">地址：</span>{{drawerData.agent_address}}
                </el-col>
              </el-row>
            </div>
            <div class="line pt-10">
              <h3>用户信息</h3>
              <el-row :gutter="15">
                <el-col class="item" :span="24">
                  <span class="label">用户ID：</span>{{drawerData.uid}}
                </el-col>
                <!--2期新增&修改 2023.11.23-->
                <el-col class="item" :span="24">
                  <span class="label">店铺名：</span>{{drawerData.store_name}}
                </el-col>
                <el-col class="item" :span="24">
                  <span class="label">店铺门头照：</span>
                  <UploadViewer
                    v-if="drawerData.store_head_image"
                    type="view"
                    v-model:modelValue="drawerData.store_head_image"
                  />
                </el-col>
                <el-col class="item" :span="24">
                  <span class="label">店铺环境照：</span>
                  <UploadViewer
                    v-if="drawerData.store_image"
                    type="view"
                    v-model:modelValue="drawerData.store_image"
                  />
                </el-col>
                <el-col class="item" :span="24">
                  <span class="label">地区：</span>{{drawerData.area_code_name}}
                </el-col>
                <el-col class="item" :span="24">
                  <span class="label">详细地址：</span>{{drawerData.address}}
                </el-col>
                <el-col class="item" :span="24">
                  <span class="label">营业执照：</span>
                  <UploadViewer
                    v-if="drawerData.business_license"
                    type="view"
                    v-model:modelValue="drawerData.business_license"
                  />
                </el-col>
                <el-col class="item" :span="24">
                  <span class="label">营业执照号：</span>
                  <span class="val">{{drawerData.business_license_txt}}</span>
                </el-col>
                
                <el-col class="item" :span="12">
                  <span class="label">法人姓名：</span>{{drawerData.realname}}
                </el-col>
                <el-col class="item" :span="12">
                  <span class="label">联系手机号：</span>{{drawerData.phone}}
                </el-col>
                <el-col class="item" :span="12">
                  <span class="label">身份证正面：</span>
                  <UploadViewer
                    v-if="drawerData.id_card_front"
                    type="view"
                    v-model:modelValue="drawerData.id_card_front"
                  />
                </el-col>
                <el-col class="item" :span="12">
                  <span class="label">身份证反面：</span>
                  <UploadViewer
                    v-if="drawerData.id_card_back"
                    type="view"
                    v-model:modelValue="drawerData.id_card_back"
                  />
                </el-col>
              </el-row>
            </div>
            
            <h3 style="margin: 0; padding: 15px 0">操作记录</h3>
            <el-table
              :data="drawerData.log"
              border
              highlight-current-row
              max-height="300"
            >
              <template v-for="col in columns" :key="col">
                <el-table-column v-bind="col">
                  <template v-if="col.prop === 'actContent'" #default="{row}">
                    <div class="act-content">
                      <div v-if="row.system_type_name">操作平台：{{ row.system_type_name }}</div>
                      <template v-if="row.after_data">
                        <div v-if="row.after_data.remark">{{ row.after_data.status === 4 ? '修改内容' : '回复内容' }}：{{ row.after_data.remark }}</div>
                        <div v-if="row.after_data.images">
                          <span class="label">留言照片：</span>
                          <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                            <template v-for="(img, i) in row.after_data.images.split(',')" :key="i">
                              <el-image
                                style="width: 60px; height: 60px; margin: 0 5px 5px 0"
                                :src="img"
                                :preview-src-list="row.after_data.images.split(',')"
                                :initial-index="i"
                                preview-teleported
                              />
                            </template>
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          
          </div>
        </div>
      </template>
    </aw-tabler>
    
    <el-dialog
      v-model="disposeDialog"
      title="处理"
      width="600px"
    >
      <el-form
        ref="disposeFormEle"
        class="dispose-form"
        :model="disposeForm"
        label-width="150px"
        label-suffix="："
      >
        <el-form-item
          label="选择处理方式"
          prop="status"
          :rules="{ required: true, message: '请选择' }"
        >
          <el-radio-group v-model="disposeForm.status">
            <el-radio :label="1">审核通过</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="回复内容" prop="content">
          <el-input
            v-model="disposeForm.remark"
            placeholder="请输入回复内容"
            type="textarea"
            :maxlength="50"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="留言图片（选填）" prop="images">
          <div style="display: flex; flex-direction: column;">
            <div v-if="disposeForm.images.length > 0" style="display: flex; flex-direction: row; margin-bottom: 10px">
              <template
                v-for="(img, i) in disposeForm.images"
                :key="img"
              >
                <el-image
                  style="width: 80px; height: 80px; margin: 0 5px 0 0;"
                  :src="img"
                  :preview-src-list="disposeForm.images"
                  :initial-index="i"
                  preview-teleported
                />
              </template>
            </div>
            <aw-upload
              v-if="disposeForm.images.length < 4"
              :mode="uploadMode"
              @action="setUploadImg"
            >
              <template #button>
                <el-button type="primary" plain>上传图片</el-button>
              </template>
            </aw-upload>
            <p class="tips">支持扩展名：*.jpg、*.jpeg、*.png；最多上传4张图片，每张图片大小不超过5Mb</p>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="closeDispose">取消</el-button>
        <el-button type="primary" :loading="disposeLoading" @click="submitDispose">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="jsx">
import {reactive, ref, nextTick} from 'vue'
import {http, tool} from "@/assets/js"
import { tableColumn } from '@/assets/js/extend/tableColumn'
import {useRoute} from 'vue-router'
import {dayjs, ElMessage} from 'element-plus'
import areaCode from "@/assets/json/areacode.json"
import UploadViewer from '../components/uploadViewer.vue'

const route = useRoute()
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.roles,'component'))

const handlerExport = () => {
  const filter = JSON.parse(JSON.stringify(tableJSON.value.filter))
  if(filter && filter.audittime && Array.isArray(filter.audittime) && filter.audittime.length > 0) {
    filter.audittime = filter.audittime ? filter.audittime.map(i => i/1000).join(' ~ ') : ''
  }
  http.post('/admin/user/auditIndex', {
    ...filter,
    ...route.query,
    page: tableJSON.value.page.page,
    page_size: tableJSON.value.page.page_size,
    export: 1
  }).then(res => {
    if(res.code === 0) {
      ElMessage.success(res.message)
    }
  })
}
const drawerData = ref({})
const drawerLoading = ref(false)
const statusInfo = ref()
const getUserInfo = (id) => {
  drawerLoading.value = true
  http.post(`/admin/user/auditInfo`, {id: id}).then(res => {
    drawerData.value = res
    if(Array.isArray(res.log) && res.log.length > 0) {
      statusInfo.value = res.log[res.log.length - 1]
      if(statusInfo.value.after_data.images) {
        statusInfo.value.after_data.images = statusInfo.value.after_data.images.split(',')
      }
    }
  }).finally(() => {
    drawerLoading.value = false
  })
}

const tableJSON = ref({
  title: '认证审核',
  loading: false,
  filter: {
    phone: '',
    agent_name: '',
    status: '',
    audit_name: '',
    audittime: '',
    username: '', // 代理商手机号
    store_name: '', // 店铺名
    realname: '', // 法人姓名
    user_area: '', // 地区
    business_license_txt: '', // 营业执照号
  },
  datas: [],
  columns: [
    ...tableColumn([
      {
        title: 'ID',
        key: 'id',
        width: '100px'
      },
      {
        title: '代理商信息',
        key: '',
        minWidth: '180px',
        render: (data) => {
          return (
            <div>
              <p>代理商：{data.name}</p>
              <p>手机号：{data.username}</p>
              <p>地址：{data.agent_area}</p>
            </div>
          )
        }
      },
      {
        title: '用户ID',
        key: 'uid',
        width: '100px'
      },
      {
        title: '店铺名',
        key: 'store_name',
        minWidth: '120px'
      },
      {
        title: '法人姓名',
        key: 'realname',
        minWidth: '130px'
      },
      {
        title: '联系手机号',
        key: 'phone',
        width: '160px'
      },
      {
        title: '省市区',
        key: 'user_area',
        minWidth: '140px'
      },
      {
        title: '营业执照号',
        key: 'business_license_txt',
        minWidth: '140px'
      },
      {
        title: '提交人',
        key: 'create_name',
        minWidth: '100px'
      },
      {
        title: '提交时间',
        key: 'uptime',
        minWidth: '140px',
        time: true
      },
      {
        title: '状态',
        key: 'status',
        minWidth: '80px',
        render: (data) => {
          return (
            <div>
              { data.status === 1 ? <span style="color:green">通过</span> : data.status === 3 ? <span style="color:red">驳回</span> : <span>待处理</span> }
            </div>
          )
        }
      },
      {
        title: '审核人',
        key: 'audit_name',
        minWidth: '100px'
      },
      {
        title: '审核时间',
        key: 'audittime',
        minWidth: '140px',
        time: true
      }
    ]),
    {
      fixed: 'right',
      title: '操作',
      key: 'act',
      width: '100px',
      buttons: [
        {
          name: '查看',
          props: {type: 'primary'},
          show: () => powerlimits.includes('rzsh_ckxq'),
          action: (data) => {
            tableJSON.value.drawer.show = true
            tableJSON.value.drawer.title = '审核详情'
            getUserInfo(data.id)
          }
        }
      ]
    }
  ],
  page: {
    total: 0,
    page: 1,
    page_size: 10,
    sizeChange: (val) => {
      tableJSON.value.page.page_size = val
      tableJSON.value.page.page = 1
      tableJSON.value.action(tableJSON.value.filter)
    },
    currentChange: (val) => {
      tableJSON.value.page.page = val
      tableJSON.value.action(tableJSON.value.filter)
    }
  },
  action: async (params) => {
    tableJSON.value.loading = true
    const filter = params ? JSON.parse(JSON.stringify(params)) : null
    if(filter && filter.audittime && Array.isArray(filter.audittime) && filter.audittime.length > 0) {
      filter.audittime = filter.audittime.map(i => i/1000).join(' ~ ')
    }
    if(powerlimits.includes('rzsh_lb')){
      http.post('/admin/user/auditIndex', {
        ...filter,
        ...route.query,
        page: tableJSON.value.page.page,
        page_size: tableJSON.value.page.page_size
      }).then(result => {
        tableJSON.value.datas = result.data
        tableJSON.value.page.total = result.total || Infinity
        tableJSON.value.page.page = result.current_page
        tableJSON.value.page.page_size = result.per_page
      }).finally(() => {
        tableJSON.value.loading = false
      })
    } else {
      tableJSON.value.datas = []
      tableJSON.value.page.total = Infinity
    }
    return
  },
  extras: [
    {
      name: '导出',
      props: { type: 'warning', plain: true, disabled: !powerlimits.includes('rzsh_dc') },
      show: () => true,
      action: () => {
        handlerExport()
      }
    }
  ],
  drawer: {
    show: false,
    title: '',
    size: '70%',
    close: () => {
      tableJSON.value.drawer.show = false
    }
  }
})

const columns = ref([
  {
    prop: 'name',
    label: '操作人'
  },
  {
    prop: 'addtime',
    label: '操作时间',
    width: '180',
    formatter: (row) => {
      return row.addtime ? dayjs.unix(row.addtime).format('YYYY-MM-DD HH:mm:ss') : ''
    }
  },
  {
    prop: 'remark',
    label: '操作类型'
  },
  {
    prop: 'actContent',
    label: '操作内容',
    minWidth: '190px'
  },
])

const uploadMode = ref({
  uploadinfo: {
    httpRequest: true,
    limit: 5,
    rules: {
      images: {
        image_width: 0, //图片宽度，0表示不限宽
        image_height: 0, //图片高度，0表示不限高
        size: 1024*5, //文件大小（单位kb）
        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'], //文件格式
        suffix: ['jpeg', 'jpg', 'gif', 'bmp', 'png'], //文件后缀-用于判断
        typechart: 'png/bmp/gif/jpg/jpeg' //文件格式提示规则
      }
    }
  }
})
// 处理
const disposeDialog = ref(false)
const disposeFormEle = ref()
const disposeForm = ref({
  status: 1,
  remark: '',
  images: []
})

const setUploadImg = (e) => {
  if(e.actionData.res.status === 200) {
    disposeForm.value.images.push(e.actionData.url)
  }
}
const openDispose = () => {
  disposeDialog.value = true
}
const closeDispose = () => {
  disposeFormEle.value.resetFields()
  nextTick(() => {
    disposeForm.value = {
      status: 1,
      remark: '',
      images: []
    }
    uploadMode.value.fileList = []
  })
  disposeDialog.value = false
}
const disposeLoading = ref(false)
const submitDispose = () => {
  disposeFormEle.value.validate((valid) => {
    if(valid) {
      disposeLoading.value = true
      http.post(`/admin/user/auditHandle`, {
        id: drawerData.value.id,
        ...disposeForm.value,
        images: disposeForm.value.images.join(',')
      }).then((res) => {
        if(res) {
          ElMessage.success('处理成功')
          closeDispose()
          tableJSON.value.drawer.show = false
          tableJSON.value.action({})
        }
      }).finally(() => {
        disposeLoading.value = false
      })
    }
  })
}

</script>

<style lang="scss" scoped>
.search-form{
  :deep(.el-form-item){
    width: 330px;
    .el-select,
    .el-cascader{
      width: 100%;
    }
  }
}
.audit-show{
  padding: 15px;
  .hd{
    padding: 20px 0;
    border-bottom: 1px solid var(--el-border-color-light);
    h2{
      margin: 0;
    }
    .btns{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  .bd{
    //padding: 20px 0;
    h3{
      margin: 0;
      padding-bottom: 10px;
    }
    .item{
      display: flex;
      flex-direction: row;
      padding: 10px 0;
      .label{
        white-space: nowrap;
        color: #777;
      }
    }
    .pt-10{
      padding: 10px 0;
    }
    .line{
      border-bottom: 1px solid var(--el-border-color-light);
    }
    
    .act-content{
    
    }
  }
}
.dispose-form{
  padding: 0 20px;
  .tips{
    margin-top: 10px;
    line-height: 22px;
    font-size: 12px;
    color: #888;
  }
}
</style>
